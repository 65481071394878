<template>
    <modal ref="modalEliminarProducto" titulo="Eliminar Producto" icon="trash" tamano="modal-xl" no-aceptar no-cerrar eliminar @eliminar="eliminar_producto">
        <ValidationObserver ref="validator">
            <div class="row mx-0">
                <div class="col-auto px-4" />
                <div class="col">
                    <p class="text-general f-600 f-17 text-center">
                        ¿Realmente desea eliminar este producto?
                    </p>
                    <p class="text-general f-15 pl-2 my-3">
                        Esta acción es irreversible y perderá toda la información de venta del producto, si no va a volver a vender el producto le sugerimos que lo inactive.
                    </p>
                    <p class="text-general f-15 pl-2 my-3">
                        Por favor, revise la información de comportamiento del producto antes de tomar cualquier decisión.
                    </p>
                    <p class="text-general pl-3 f-15">
                        Justifica la eliminación
                    </p>
                    <div class="row mx-0 mb-4">
                        <div class="col-12 px-2">
                            <ValidationProvider v-slot="{errors}" rules="required|max:250" name="justificación">
                                <el-input v-model="justificacion" type="textarea" class="w-100 br-10" :rows="4" maxlength="250" show-word-limit />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mt-3">
                        <div class="col">
                            <div class="row mx-0 mb-1">
                                <div class="col-auto d-middle">
                                    <img
                                    :src="producto.imagen"
                                    :class="producto.imagen == '/img/no-imagen/mercado.svg' ? '' : 'obj-cover'"
                                    class="br-12 border"
                                    style="width:118px;height:118px;"
                                    />
                                </div>
                                <div class="col">
                                    <p class="text-general f-600 f-14">
                                        Creado:
                                    </p>
                                    <p class="text-general f-14">
                                        {{ producto.creador }}
                                    </p>
                                    <p class="text-general f-14">
                                        {{ formatearFecha(producto.created_at,'D MMM Y hh:mmA') }}
                                    </p>
                                    <p class="text-general f-600 f-14 mt-3">
                                        Actualizado:
                                    </p>
                                    <p class="text-general f-14">
                                        {{ producto.editor }}
                                    </p>
                                    <p class="text-general f-14">
                                        {{ formatearFecha(producto.updated_at,'D MMM Y hh:mmA') }}
                                    </p>
                                </div>
                            </div>
                            <div class="row mx-0 my-2 mt-5">
                                <div class="col">
                                    <p class="text-general f-14 f-600">
                                        Stock actual
                                    </p>
                                </div>
                                <div class="col-3 text-left">
                                    <el-tooltip placement="bottom" content="Stock en cedis" effect="light">
                                        <i class="icon-warehouse text-general f-20" />
                                    </el-tooltip>
                                </div>
                                <div class="col-3 text-left">
                                    <el-tooltip placement="bottom" content="Stock en leecheros" effect="light">
                                        <i class="icon-leechero-cap text-general f-20" />
                                    </el-tooltip>
                                </div>
                            </div>
                            <div v-for="(data,d) in stocks" :key="d" class="row mx-0 pl-2 mt-2 text-general">
                                <div class="col f-400 f-17">
                                    {{ data.nombre }}
                                </div>
                                <div class="col-3 f-600 f-17 text-left">
                                    {{ agregarSeparadoresNumero(data.stock_cedis,0) }}
                                </div>
                                <div class="col-3 f-600 f-17 text-left">
                                    {{ agregarSeparadoresNumero(data.stock_leecheros,0) }}
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <p class="text-general f-14 mb-3">
                                Unidades de producto por estados
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto px-0">
                                    <div style="height:42px;" />
                                    <div class="row mx-0 my-1">
                                        <el-tooltip placement="bottom" content="Por leecheros" effect="light">
                                            <i class="icon-leechero-cap text-general f-20" />
                                        </el-tooltip>
                                    </div>
                                    <hr class="mb-2" />
                                    <div class="row mx-0 my-1">
                                        <el-tooltip placement="bottom" content="Por Clientes" effect="light">
                                            <i class="icon-cliente text-general f-20" />
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div v-for="(data, d) in unidades_x_estados" :key="d" class="col px-0 text-center">
                                    <el-tooltip placement="bottom" :content="data.tooltip" effect="light">
                                        <i :class="`icon-${data.icon} f-24 text-general`" />
                                    </el-tooltip>
                                    <p class="text-general2 f-600 f-16 mt-3">
                                        {{ agregarSeparadoresNumero(data.leecheros) }}
                                    </p>
                                    <hr />
                                    <p class="text-general2 f-600 f-16 mt-3">
                                        {{ agregarSeparadoresNumero(data.clientes) }}
                                    </p>
                                </div>
                            </div>
                            <p class="text-general f-14 f-500 mt-5">
                                Ventas a leecheros
                            </p>
                            <div class="row mx-0 align-items-center my-1">
                                <div class="col-6 px-0">
                                    <div class="text-general px-2 d-middle f-15 bg-light-f5 border br-12 tres-puntos py-1">
                                        Último mes
                                        <span class="ml-auto f-600">
                                            {{ agregarSeparadoresNumero(ventas.ultimo_mes.cantidad,0) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="text-general px-3 f-15 bg-light-f5 border br-12 tres-puntos py-1">
                                        {{ separadorNumero(ventas.ultimo_mes.total_final) }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0 align-items-center my-1">
                                <div class="col-6 px-0">
                                    <div class="text-general px-2 d-middle f-15 bg-light-f5 border br-12 tres-puntos py-1">
                                        Toda la vida del producto
                                        <span class="ml-auto f-600">
                                            {{ agregarSeparadoresNumero(ventas.todas.cantidad,0) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="text-general px-3 f-15 bg-light-f5 border br-12 tres-puntos py-1">
                                        {{ separadorNumero(ventas.todas.total_final) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto px-4" />
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Productos from "~/services/productos/productosAdmin"
import {mapGetters} from 'vuex'

export default {
    data(){
        return {
            url_proyecto: 'https://vixark.b-cdn.net/lp-i-i-p/mini-brownies-rellenos-bimbo-220g-arequipe.jpg',
            unidades_x_estados: [
                {
                    icon: 'cart-outline',
                    leecheros: 0,
                    clientes: 0,
                    tooltip: 'En carrito'
                },
                {
                    icon: 'receipt',
                    leecheros: 0,
                    clientes: 0,
                    tooltip: 'Sin Confirmar'
                },
                {
                    icon: 'ok-circled',
                    leecheros: 0,
                    clientes: 0,
                    tooltip: 'Confirmados'
                },
                {
                    icon: 'cart-arrow-down',
                    leecheros: 0,
                    clientes: 0,
                    tooltip: 'En alistamiento'
                },
                {
                    icon: 'shopping-outline',
                    leecheros: 0,
                    clientes: 0,
                    tooltip: 'Empacados'
                },
                {
                    icon: 'bike',
                    leecheros: 0,
                    clientes: 0,
                    tooltip: 'Enviados'
                }
            ],
            justificacion: '',
            id_producto: null,
            stocks: [],
            ventas: {
                ultimo_mes:{
                    cantidad:0,
                    total_final:0,
                },
                todas:{
                    cantidad:0,
                    total_final:0,
                },
            },
            producto:{
                imagen:'',
                creador:'',
                created_at:'',
                editor:'',
                updated_at:'',
            },
            detalle_estados: null,
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
    },
    methods: {
        toggle(producto){
            this.id_producto = producto.id
            this.producto = { ...producto }
            this.eliminarDetalleProducto()
            this.$refs.modalEliminarProducto.toggle();
        },
        async eliminarDetalleProducto(){
            try {
                let params = {
                    id_moneda: this.id_moneda
                }
                const { data } = await Productos.eliminarDetalleProducto(this.id_producto, params)

                this.stocks = data.stocks
                this.ventas = data.ventas
                this.detalle_estados = data.detalle_estados
                this.unidades_x_estados[0].leecheros = data.detalle_estados.carrito
                this.unidades_x_estados[0].clientes = data.detalle_estados.carrito_cliente
                this.unidades_x_estados[1].leecheros = data.detalle_estados.sin_confirmar
                this.unidades_x_estados[1].clientes = data.detalle_estados.sin_confirmar_cliente
                this.unidades_x_estados[2].leecheros = data.detalle_estados.confirmar
                this.unidades_x_estados[2].clientes = data.detalle_estados.confirmar_cliente
                this.unidades_x_estados[3].leecheros = data.detalle_estados.alistando
                this.unidades_x_estados[3].clientes = data.detalle_estados.alistando_cliente
                this.unidades_x_estados[4].leecheros = data.detalle_estados.empacado
                this.unidades_x_estados[4].clientes = data.detalle_estados.empacado_cliente
                this.unidades_x_estados[5].leecheros = data.detalle_estados.enviado
                this.unidades_x_estados[5].clientes = data.detalle_estados.enviado_cliente
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminar_producto(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    justificacion: this.justificacion
                }
                const valid = await this.$refs.validator.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                const {data} = await Productos.eliminar(this.id_producto, params)

                this.$refs.modalEliminarProducto.toggle()
                this.$store.commit('productos/productos/delete_producto', this.id_producto)
                this.$store.commit('productos/productos/set_id_producto', null)
                this.$store.commit('productos/productos/set_is_kit', false)
                this.notificacion('Mensaje','Producto removido exitosamente','success')
                this.justificacion = ''
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
